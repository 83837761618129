<template>
  <v-btn
    color="tertiary"
    class="app-cta app-cta--font-title app-cta--tertiary app-cta--big"
    variant="flat"
    v-bind="$attrs"
  >
    <slot />
  </v-btn>
</template>
